define(['santa-components', 'lodash', 'reactDOM'], function (santaComponents, _, ReactDOM) {
    'use strict';

    /**
     * @class core.facebookComponentMixin
     */

    /**
     * Checks if the component height or width was changed
     * @param newLayout
     * @param oldLayout
     * @returns {boolean}
     */
    function didSizeChange(newLayout, oldLayout) { // eslint-disable-line complexity
        if (newLayout && newLayout.height && newLayout.width && oldLayout && oldLayout.height && oldLayout.width) {
            return newLayout.height !== oldLayout.height || newLayout.width !== oldLayout.width;
        }
        return false;
    }

    return {

        getInitialState() {
            this._lastHref = this.getHref(this.props);
            return {};
        },

        propTypes: {
            externalScriptLoader: santaComponents.santaTypesDefinitions.SiteAspects.externalScriptLoader,
            cookie: santaComponents.santaTypesDefinitions.RequestModel.cookie,
            currentUrl: santaComponents.santaTypesDefinitions.currentUrl,
            userLanguage: santaComponents.santaTypesDefinitions.WixUserSantaTypes.userLanguage.isRequired
        },

        /**
         * Loads the facebook SDK
         */
        loadScript() {
            if (typeof window !== 'undefined' && !window.FB) {// server side rendering fix
                this.props.externalScriptLoader.loadScript('FACEBOOK', null, {
                    currentUrl: this.props.currentUrl,
                    cookie: this.props.cookie,
                    userLanguage: this.props.userLanguage
                });
            }
        },

        /**
         * Re renders the facebook plugin DOM node
         */
        parseFacebookPluginDomNode() {
            if (_.has(window, 'FB.XFBML.parse')) {
                window.FB.XFBML.parse(ReactDOM.findDOMNode(this));
            }
        },


        componentDidMount() {
            this.loadScript();
            this.parseFacebookPluginDomNode();
        },

        componentDidUpdate(nextProps) {
            const newHref = this.getHref(this.props);

            if (!_.isEqual(nextProps.compData, this.props.compData) ||
                !_.isEqual(nextProps.compProp, this.props.compProp) ||
                didSizeChange(nextProps.style, this.props.style) ||
                newHref !== this._lastHref) {
                this.parseFacebookPluginDomNode();
            }
            this._lastHref = newHref;
        }
    };
});

/**
 * Created by alexandreroitman on 14/11/2016.
 */
define(['santa-components'], function (santaComponents) {
    'use strict';

    return {
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            currentPageSocialUrl: santaComponents.santaTypesDefinitions.Social.CurrentPageSocialUrl,
            mainPageSocialUrl: santaComponents.santaTypesDefinitions.Social.MainPageSocialUrl
        },

        getSocialUrl(forceMainPage) {
            let pageUrl;

            if (forceMainPage) {
                pageUrl = this.props.mainPageSocialUrl;
            } else {
                pageUrl = this.props.currentPageSocialUrl;
            }

            return pageUrl;
        }
    };
});

define(['santa-components'], function (santaComponents) {
    'use strict';

    function getLanguage(dataLang, userLanguage) {
        if (dataLang === 'userLang') {
            return userLanguage;
        }
        return dataLang;
    }

    return {
        getInitialState() {
            return {
                width: this.props.style.width,
                height: this.props.style.height
            };
        },
        propTypes: {
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
            userLanguage: santaComponents.santaTypesDefinitions.WixUserSantaTypes.userLanguage.isRequired,
            getTranslatedAriaLabel: santaComponents.santaTypesDefinitions.Accessibility.getTranslatedAriaLabel.isRequired
        },
        componentDidMount() {
            window.addEventListener('message', this.processMessage);
        },
        componentWillUnmount() {
            window.removeEventListener('message', this.processMessage);
        },
        processMessage(event) {
            if (event.data && event.data.type === 'twitterSize' && event.data.compId === this.props.id) {
                this.registerReLayout();
                this.setState(event.data.size);
            }
        },
        getLanguage() {
            return getLanguage(this.props.compProp.dataLang, this.props.userLanguage);
        },

        getIframeTitle() {
            const titleName = this.getIframeTitleName();
            return this.props.getTranslatedAriaLabel('component_label', titleName, titleName);
        },

        getSkinProperties() {
            const IframeTitle = this.getIframeTitle();
            const iframeSrc = this.getIFrameSrc();
            return {
                '': {
                    style: {
                        width: this.state.width,
                        height: this.state.height
                    },
                    title: IframeTitle,
                    'aria-label': IframeTitle,
                    'data-src': iframeSrc,
                    tagName: 'wix-iframe'
                },
                iframe: {
                    'data-src': iframeSrc,
                    width: this.state.width,
                    height: this.state.height,
                    title: IframeTitle
                }
            };
        }
    };
});
